body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ms-DetailsRow-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body,
html,
#root,
#root > .fabric {
  height: 100%;
}

// Smaller screen sizes
@media (max-width: 768px) {
  dd {
    //  Reduce indentation for mobile readability
    margin-left: 3px;
  }
}

// Print mode support for  on all pages
@media print {
  #root,
  * {
    height: auto !important;
    overflow: visible !important;
    box-shadow: none !important;

    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: avoid !important;
  }

  @page {
    margin: 20px;
  }

  header {
    padding: 0px !important;
  }

  // Prevent showing these in print mode
  header[role='banner'],
  footer,
  nav {
    display: none !important;
  }

  table {
    width: 99%;
    border-collapse: collapse;
    page-break-inside: auto !important;
    overflow: visible;

    tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    th,
    td {
      border: 1px solid #ddd !important;
      padding: 4px !important;
      text-align: left;
      word-wrap: break-word;
    }
  }

  .print-breadcrumb {
    ol {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .print-details-header {
    i + span {
      visibility: hidden !important;
    }
  }

  .print-page {
    padding: 0px !important;
  }

  .print-visibility-hidden {
    visibility: hidden !important;
  }

  #scroll-container {
    flex-grow: 0 !important;
  }

  #custom-scrollable-pane {
    height: auto !important;
    overflow: visible !important;
  }

  // This targets DetailsList component
  .ms-Viewport {
    display: inline-grid;
    .ms-DetailsList {
      display: inline-grid;
      div[role='grid'] {
        display: inline-grid;
        .ms-DetailsList-contentWrapper {
          display: inline-grid;
          .ms-SelectionZone {
            display: inline-grid;
            .ms-DetailsRow-cell {
              white-space: normal;
            }
          }
        }
      }
    }
  }

  // Removes border and shadows from the pages (CVE, Release Notes, and main page)
  #releaseNotePageCard,
  #page-vulnerability-detail {
    width: 100%;
    padding: 0px !important;

    > div {
      box-shadow: none !important;
      > div {
        border-bottom: none !important;
        > div {
          display: inline-grid !important;
        }
      }
    }

    #cvss-table {
      display: inline-grid;

      .print-cvss-label {
        i + span {
          padding-left: 24px;
        }
      }
    }
  }
}

// Dictionary list titles should default to semibold
dt {
  font-weight: 600;
}
